<template>
  <page fluid="lg" title="Editar cuadro de mando">
    <formulario :cuadroMando="cuadroMando" @submit="actualizar"> </formulario>
  </page>
</template>

<script>
import Formulario from "./Formulario.vue";
import * as apiCuadrosMando from "@/services/cuadrosMando.js";

export default {
  components: { Formulario },
  data() {
    return {
      cuadroMando: {},
    };
  },
  methods: {
    async cargar(id) {
      let resultado = await apiCuadrosMando.cargar(id);
      this.cuadroMando = resultado;
    },
    async actualizar() {
      let ok = await apiCuadrosMando.actualizar(this.cuadroMando);
      if (ok) {
        this.$emit("userMessage", "Se ha actualizado el cuadro de mando");
        this.$router.push({ path: "/administracion/cuadros-de-mando" });
      }
    },
  },
  mounted() {
    this.cargar(this.$route.params.id);
  },
};
</script>
